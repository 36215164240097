import "core-js/modules/es.array.push.js";
import $ from "jquery";
import store from "@/state/store.js";
import messageModal from "./messageModal.vue";
import helpIcon from "./helpIcon.vue";
export default {
  components: {
    messageModal,
    helpIcon
  },
  data() {
    return {
      international_rates: [],
      international_templates: [],
      rateLoading: false,
      templateLoading: false,
      from: "",
      dataPayload: {
        currentModal: "",
        selectedKey: "",
        selectedMessage: {}
      },
      searchRates: "",
      searchTemplates: "",
      // pagination
      pageSizeRates: 10,
      totalRates: 0,
      currentPageRates: 1,
      pageSizeTemplates: 10,
      totalTemplates: 0,
      currentPageTemplates: 1,
      showInternationalHelp: false
    };
  },
  methods: {
    navigateToTemplates() {
      this.$router.replace("/sms/messages").catch(err => {
        if (err.name !== "NavigationDuplicated") throw err;
      });
    },
    scheduleMessage() {
      this.$router.push("/sms/scheduled_messages").catch(err => {
        if (err.name !== "NavigationDuplicated") throw err;
      });
    },
    openMessageModal(type) {
      this.showInternationalHelp = false;
      if (type === "sendMessage") {
        this.from = "simpleMessage";
        this.$emit("SMSType", "simpleMessage");
        store.dispatch("uistate/addContactFormData", {
          title: "Send Message",
          type: "selectSenderId",
          fromMessage: true,
          whichType: "International"
        });
        // Set Scheduled Type to false
        store.dispatch("uistate/setFromScheduled", false);
      } else if (type === "template") {
        this.$emit("SMSType", "template");
        this.from = "template";
        store.dispatch("uistate/addContactFormData", {
          title: "Create a template",
          type: "template",
          fromTemplate: false
        });
        store.dispatch("modal/updateCurrentPage", "template");
      }
      return this.showModal();
    },
    showModal() {
      let element = this.$refs.messageModal.$el;
      $(element).modal({
        backdrop: "static",
        keyboard: false,
        show: true
      });
    },
    openGeneralModals({
      message,
      key,
      type
    }) {
      if (type === "send") {
        this.dataPayload = {
          currentModal: "send",
          selectedKey: key,
          selectedMessage: message,
          goStraightToEdit: false
        };
        store.dispatch("uistate/addContactFormData", {
          title: "",
          type: "view"
        });
        store.dispatch("modal/addFormData", {
          currentPage: "",
          nextPage: "",
          prevPage: "",
          formData: {
            id: message.id,
            body: message.body,
            title: message.title,
            type: message.type,
            isEdit: false,
            pageTitle: ""
          }
        });
      }
      if (type === "delete") {
        this.dataPayload = {
          currentModal: "delete",
          selectedKey: key,
          selectedMessage: message
        };
        store.dispatch("uistate/addContactFormData", {
          title: "",
          type: "delete"
        });
      }
      return this.showModal();
    },
    handleDeleteSucccess() {
      this.refetchData();
    },
    saveTemplate() {
      this.refetchData();
    },
    refetchData() {
      this.rateLoading = true;
      this.templateLoading = true;
      store.dispatch("international/getRates").then(response => {
        this.international_rates = response.data;
        this.rateLoading = false;
      });
      store.dispatch("campaigns/fetchTemplate").then(res => {
        this.international_templates = res.data.filter(template => template.type == 4);
        this.templateLoading = false;
      }).catch(err => {});
    },
    handleCurrentChangeTemplates(val) {
      this.currentPageTemplates = val;
    },
    handleCurrentChangeRates(val) {
      this.currentPageRates = val;
    },
    showInternationalHelpModal() {
      this.showInternationalHelp = true;
    }
  },
  computed: {
    internationalRates() {
      // Filter by search
      if (this.searchRates) {
        return this.international_rates.filter(rate => rate.country_name.toLowerCase().includes(this.searchRates.toLowerCase()) || rate.country_code.toLowerCase().includes(this.searchRates.toLowerCase())).slice((this.currentPageRates - 1) * this.pageSizeRates, this.currentPageRates * this.pageSizeRates);
      }
      return this.international_rates.slice((this.currentPageRates - 1) * this.pageSizeRates, this.currentPageRates * this.pageSizeRates);
    },
    internationalTemplates() {
      // Filter by search
      if (this.searchTemplates) {
        return this.international_templates.filter(template => template.title.toLowerCase().includes(this.searchTemplates.toLowerCase())).slice((this.currentPageTemplates - 1) * this.pageSizeTemplates, this.currentPageTemplates * this.pageSizeTemplates);
      }
      return this.international_templates.slice((this.currentPageTemplates - 1) * this.pageSizeTemplates, this.currentPageTemplates * this.pageSizeTemplates);
    },
    dynamicModalWidth() {
      if (screen.width < 768) {
        return "98%";
      } else {
        return "50%";
      }
    },
    walletBalance() {
      return store.getters["wallet/ballance"] ? store.getters["wallet/ballance"].wallet : 0;
    }
  },
  mounted() {
    this.rateLoading = true;
    this.templateLoading = true;
    this.showInternationalHelp = true;
    store.dispatch("international/getRates").then(response => {
      this.international_rates = response.data;
      this.rateLoading = false;
      this.totalRates = this.international_rates.length;
    });
    store.dispatch("campaigns/fetchTemplate").then(res => {
      this.international_templates = res.data.filter(template => template.type == 4);
      this.templateLoading = false;
      this.totalTemplates = this.international_templates.length;
    }).catch(err => {});
  }
};